// import {Link} from '@remix-run/react';
import {isEmptyString} from '@ivosabev/helpers/isEmptyString';
import {useNavigate} from '@remix-run/react';

export type ButtonProps = {
  appearance?: 'primary' | 'outline' | 'link';
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  href?: string;
  onClick?: React.MouseEventHandler;
  target?: '_blank' | '_self';
  color: string;
  size?: 'base' | 'lg' | 'xl' | '2xl';
  type?: 'button' | 'submit' | 'reset';
};

const getColorClassNames = (appearance: string, color: string, disabled: boolean) => {
  if (appearance === 'outline' || appearance === 'link') {
    if (disabled) {
      return 'text-gray-300 border-gray-300 cursor-not-allowed';
    }

    switch (color) {
      case 'amber':
        return 'text-amber-500 border-amber-200 hover:border-amber-500';
      case 'cyan':
        return 'text-cyan-500 border-cyan-200 hover:border-cyan-500';
      case 'darkGray':
        return 'text-gray-800 border-gray-300 hover:border-gray-800';
      case 'emerald':
        return 'text-emerald-500 border-emerald-200 hover:border-emerald-500';
      case 'fuchsia':
        return 'text-fuchsia-500 border-fuchsia-200 hover:border-fuchsia-500';
      case 'gray':
        return 'text-gray-500 border-gray-200 hover:border-gray-400';
      case 'lightBlue':
        return 'text-lightBlue-500 border-lightBlue-200 hover:border-lightBlue-500';
      case 'blue-800':
        return 'text-blue-800 border-blue-500 hover:border-blue-800';
      case 'blue':
      case 'blue-500':
        return 'text-blue-500 border-blue-200 hover:border-blue-500';
      case 'orange':
      case 'orange-500':
        return 'text-orange-500 border-orange-200 hover:border-orange-500';
      case 'pink':
        return 'text-pink-500 border-pink-200 hover:border-pink-500';
      case 'rose':
        return 'text-rose-500 border-rose-200 hover:border-rose-500';
      case 'sky':
        return 'text-sky-500 border-sky-200 hover:border-sky-500';
      case 'red':
        return 'text-red-500 border-red-200 hover:border-red-500';
      case 'teal':
        return 'text-teal-500 border-teal-200 hover:border-teal-500';
      default:
        return '';
    }
  } else if (appearance === 'primary') {
    if (disabled) {
      return 'bg-gray-300 cursor-not-allowed';
    }

    switch (color) {
      case 'amber':
        return 'bg-amber-500 hover:bg-amber-600';
      case 'cyan':
        return 'bg-cyan-500 hover:bg-cyan-600';
      case 'darkGray':
        return 'bg-gray-800 hover:bg-gray-600';
      case 'emerald':
        return 'bg-emerald-500 hover:bg-emerald-600';
      case 'fuchsia':
        return 'bg-fuchsia-500 hover:bg-fuchsia-600';
      case 'gray-300':
        return 'bg-gray-300 hover:bg-gray-400';
      case 'gray-400':
        return 'bg-gray-400 hover:bg-gray-500';
      case 'gray-500':
      case 'gray':
        return 'bg-gray-500 hover:bg-gray-400';
      case 'lightBlue':
        return 'bg-lightBlue-500 hover:lightBlue-600';
      case 'blue-800':
        return 'bg-blue-800 hover:bg-blue-900';
      case 'blue':
        return 'bg-blue-500 hover:bg-blue-600';
      case 'orange':
      case 'orange-500':
        return 'bg-orange-500 hover:bg-orange-600';
      case 'red':
        return 'bg-red-500 hover:bg-red-600';
      case 'pink':
        return 'bg-pink-500 hover:bg-pink-600';
      case 'lightRose':
        return 'bg-rose-400 hover:bg-rose-500';
      case 'rose':
        return 'bg-rose-500 hover:bg-rose-600';
      case 'sky':
        return 'bg-sky-500 hover:bg-sky-600';
      case 'teal':
        return 'bg-teal-500 hover:bg-teal-600';
      default:
        return '';
    }
     
  } else if (appearance === 'link') {
    //
  }
  return '';
};

export const getButtonClassName = (className: string, color: string, appearance: string, size: string, disabled: boolean = false) => {
  let sizeClasses = '';
  if (appearance !== 'link') {
    switch (size) {
      case 'base':
        sizeClasses = 'sm:px-5 sm:py-3 sm:text-xl px-3 py-2 text-lg';
        break;
      case 'lg':
        sizeClasses = 'sm:px-8 sm:py-4 sm:text-xl px-6 py-3.5 text-xl';
        break;
      case 'xl':
        sizeClasses = 'sm:px-8 sm:py-4 sm:text-2xl px-6 py-3.5 text-xl';
        break;
      case '2xl':
        sizeClasses = 'sm:px-8 sm:py-4 sm:text-3xl px-6 py-3.5 text-2xl';
        break;
    }
  }

  return `
    ${className}
    ${appearance === 'primary' ? 'text-white border border-transparent' : ''}
    ${appearance === 'outline' ? 'bg-transparent border-2' : ''}
    ${sizeClasses} ${getColorClassNames(appearance, color, disabled)}
    cursor-pointer text-center flex-none  font-semibold leading-6 transition-colors duration-200 sm:w-auto rounded-xl focus:outline-none inline-block`;
};

export const Button = (props: ButtonProps) => {
  const {
    appearance = 'primary',
    children,
    className = '',
    color = 'gray',
    disabled = false,
    href = '',
    onClick,
    size = 'base',
    target,
    type = 'button',
  } = props;

  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    } else if (!isEmptyString(href)) {
      if (href.indexOf('http') === 0) {
        window.location.href = href;
      } else {
        navigate(href);
      }
    }
  };

  if (href) {
    return (
      <a
        className={getButtonClassName(className, color, appearance, size, disabled)}
        href={href}
        rel="noreferrer"
        target={target}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={getButtonClassName(className, color, appearance, size, disabled)}
      disabled={disabled}
      onClick={handleClick}
      type={type}
    >
      {children}
    </button>
  );
};
